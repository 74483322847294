const res = {
    success: function(response) {
        if (response.data.code == 200 || response.data.code == 0 ) {
            return true;
        }
        return false;
    },

    config: {
        agentId: '1000005',
        corpId:'ww3419e797ef1b04ae',
        mapKey: 'TVCBZ-A4EC3-RIJ3Q-3ZEVT-DSUF5-33FAE'
    },
}

export default res;
