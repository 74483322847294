let host = '';//'https://outletsmallapi.bxncc.cc'; //'http://192.168.33.128:8000';
let imageHost = 'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com';
const api = {
    host: host,
    imageHost: imageHost,
    // login: host + '/auth/ompLogin',
    getStoreInfo: host + '/consumer/v1/wx/cp/place/getStoreInfo',
    uploadImage: host + '/consumer/v1/wx/cp/place/placeImage',
    removeImage: host + '/consumer/v1/wx/cp/place/delPlaceImage',
    placeList: host + '/consumer/v1/wx/cp/place/place',
    getJsSdkConfig: host + '/consumer/v1/wx/cp/getJsSdkConfig',
    getAgentJsSdkConfig: host + '/consumer/v1/wx/cp/getAgentJsSdkConfig',
    updateLongLatitude: host + '/consumer/v1/wx/cp/place/updateLongLatitude',
    mapSearch: host + '/consumer/v1/map/search',
    getUserInfo: host + '/consumer/v1/wx/cp/getUserInfo'
}

export default api;