//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imgdown from "@/assets/icon-down.png";
import imgdowngray from "@/assets/icon-down-gray.png";
import imgdate from "@/assets/icon-date.png";
import imgsearch from "@/assets/icon-search.png";
import imgfilter from "@/assets/icon-filter.png";
import { LocalStorage } from "quasar";
import common from "./common/common";
import api from "./networking/api";
export default {
  name: "storeDailyReportIndex",
  data() {
    return {
      form: {
        businessChannelIds: [],
        labelNames: [],
      },
      sumInfo: {
        two_years_ago_month_sales: 0,
        before_day_sales: 0,
        before_month_sales: 0,
        month_target: 0,
        year_target: 0,
      },
      minDate: new Date(2023, 6, 1),
      maxDate: new Date(),
      keywords: "",
      imgfilter,
      imgdown,
      imgdate,
      imgdowngray,
      imgsearch,
      days: 30,
      day: 1,
      businessChannelOptions: [
        {
          label: "直营正价",
          value: "202",
        },
        {
          label: "直营奥莱",
          value: "203",
        },
        {
          label: "加盟",
          value: "201",
        },
      ],
      labelOptions: [],
      //品牌
      brandOptions: [
        {
          label: "报喜鸟",
          value: "ZBXN",
        },
        {
          label: "哈吉斯",
          value: "ZHAZ",
        },
        {
          label: "奥莱",
          value: "ZOLT",
        },
        {
          label: "所罗",
          value: "ZSOL",
        },
        {
          label: "乐菲",
          value: "ZLAF",
        },
        {
          label: "恺米切",
          value: "ZCAM",
        },
        {
          label: "东博利尼",
          value: "ZTOM",
        },
      ],
      branchOfficeOptions: [
        {
          name: "凤凰东北",
          id: "Z01",
        },
        {
          name: "凤凰华北",
          id: "Z02",
        },
        {
          name: "凤凰华东",
          id: "Z03",
        },
        {
          name: "凤凰华南",
          id: "Z04",
        },
        {
          name: "凤凰华中",
          id: "Z09",
        },
        {
          name: "凤凰其他",
          id: "Z05",
        },
        {
          name: "凤凰西北",
          id: "Z06",
        },
        {
          name: "凤凰华西",
          id: "Z07",
        },
        {
          name: "凤凰中南",
          id: "Z08",
        },
        {
          name: "品牌",
          id: "R99",
        },
        {
          name: "加盟东北",
          id: "J01",
        },
        {
          name: "加盟华北",
          id: "J03",
        },
        {
          name: "加盟华东",
          id: "J04",
        },
        {
          name: "加盟华南",
          id: "J05",
        },
        {
          name: "加盟中南",
          id: "J06",
        },
        {
          name: "加盟西北",
          id: "J07",
        },
        {
          name: "加盟西南",
          id: "J08",
        },
        {
          name: "加盟华中",
          id: "J09",
        },
      ],
      brand: "",
      currentDate: new Date(),
      currentFormatDate: this.$moment(new Date()).format("yyyy-MM"),
      showPopover: false,
      showSearch: false,
      dailyList: [],
      staticSaleMap: {},
      storeNum: 0,
      noDataStoreList: [],
      managerName: "",
      showManagerPopover: false,
      analyseList: [],
      analysePopup: false,
      queryPop: false,
      sharePop: false,
      groupList: [],
      chooseGroup: {},
      showGroupDialog: false,
      sumEnumInfo: {
        storeNum: 0,
        yearTarget: 0,
        yearSum: 0,
        yearCompleteRate: 0,
      },
    };
  },
  created() {
    this.days = this.$moment(new Date(), "YYYY-MM").daysInMonth();
    this.day = new Date().getDate() - 1;
    window.document.title = "门店日报汇总";
    this.getUserInfo();
    this.getTags();
    this.requestJsSdkConfig();
  },
  computed: {
    managerList() {
      return this.dailyList.filter((v) => {
        return v.saleManager == this.managerName;
      });
    },
  },
  methods: {
    showConsole() {
      var script = document.createElement("script");
      script.src =
        "https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js";
      document.getElementsByTagName("body")[0].appendChild(script);
      script.onload = () => {
        var vConsole = new VConsole();
      };
    },
    requestJsSdkConfig: function () {
      let that = this;
      // let agentId = common.config.agentId;
      // let corpId = common.config.corpId;
      let agentId = LocalStorage.getItem("agentId");
      let corpId = LocalStorage.getItem("corpId");
      this.axios
        .get(api.getJsSdkConfig, {
          params: {
            agentId,
            corpId,
            url: encodeURIComponent(window.location.href.split("#")),
          },
        })
        .then(function (response) {
          console.log(response.data.data);
          if (common.success(response)) {
            let data = response.data.data;
            that.wxConfigData = {
              nonceStr: data.nonceStr,
              timestamp: data.timestamp,
              signature: data.signature,
              appId: data.appId,
            };
            that.wxConfig(that.wxConfigData);
          } else {
            // alert('获取JsSdkConfig失败：' + response.data.reason);
          }
        })
        .catch(function (error) {
          console.log(error);
          // alert('获取JsSdkConfig失败：' + error);
        })
        .then(function () {});
    },
    wxConfig: function (data) {
      let that = this;
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，企业微信的corpID
        timestamp: data.timestamp, //  必填，生成签名的时间戳
        nonceStr: data.nonceStr, //  必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: [
          "agentConfig",
          "openExistedChatWithMsg",
          "shareToExternalChat",
        ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      wx.ready(function () {
        console.log("ready");
        // let agentId = common.config.agentId;
        // let corpId = common.config.corpId;
        let agentId = LocalStorage.getItem("agentId");
        let corpId = LocalStorage.getItem("corpId");
        that.axios
          .get(api.getAgentJsSdkConfig, {
            params: {
              agentId,
              corpId,
              url: encodeURIComponent(window.location.href.split("#")),
            },
          })
          .then(function (response) {
            console.log(response, "AgentJsSdkConfig");
            if (common.success(response)) {
              let data = response.data.data;

              wx.agentConfig({
                corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
                timestamp: data.timestamp, //  必填，生成签名的时间戳
                nonceStr: data.nonceStr, //  必填，生成签名的随机串
                signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                jsApiList: [
                  "agentConfig",
                  "openExistedChatWithMsg",
                  "shareToExternalChat",
                ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                success: function (res) {
                  // 回调
                  console.log(res, "agentConfig");
                },
                fail: function (res) {
                  console.log(res);
                  if (res.errMsg.indexOf("function not exist") > -1) {
                    alert("版本过低请升级");
                  }
                },
              });
            } else {
              // alert('获取AgentJsSdkConfig失败：' + response.data.reason);
            }
          })
          .catch(function (error) {
            console.log(error);
            // alert('获取JsSdkConfig失败：' + error);
          })
          .then(function () {});

        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });

      wx.error(function (res) {
        console.log(res);
        // alert(JSON.stringify(res));
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    },
    openSharePop() {
      const that = this;
      let postData = {
        pager: {
          pageIndex: 0,
          pageSize: 0,
          rowsCount: 0,
          start: 0,
        },
        queryMap: {
          status: 1,
          agentId: LocalStorage.getItem("agentId"),
        },
        queryMapObject: {},
      };
      this.axios.post("/consumer/wx/cp/appchat/list", postData).then((res) => {
        console.log(res);
        if (res.data && res.data.data) {
          that.groupList = res.data.data.list;
          that.sharePop = true;
        }
      });
    },
    closeGroupDialog() {
      this.showGroupDialog = false;
    },
    shareGroup(group) {
      this.chooseGroup = group;
      this.showGroupDialog = true;
    },
    share() {
      const _this = this;
      let arr = [];
      this.managerList.map((v) => {
        arr.push(v.id);
      });
      let postData = {
        chatid: _this.chooseGroup.chatid,
        daysaleIds: arr,
      };
      this.axios
        .post("/consumer/wx/cp/appchat/shareStoreDaySale", postData)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data) {
            _this.showGroupDialog = false;
            _this.sharePop = false;
            wx.invoke(
              "openExistedChatWithMsg",
              {
                chatId: _this.chooseGroup.chatid,
                msg: {
                  msgtype: "link",
                  link: {
                    title:
                      _this.managerName +
                      _this
                        .$moment(
                          _this.currentFormatDate +
                            "-" +
                            (_this.day < 9
                              ? "0" + (_this.day + 1)
                              : _this.day + 1)
                        )
                        .format("yyyy-MM-DD") +
                      "日报",
                    desc: this.managerList[0] && this.managerList[0].branchOfficeName || "",
                    url:
                      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww3419e797ef1b04ae&redirect_uri=https%3A%2F%2F" +
                      (this.axios.defaults.baseURL == "/prod-api"
                        ? "omp.baoxiniao.com"
                        : "outletsmallapi.bxncc.cc") +
                      "%3Fappname%3D" +
                      LocalStorage.getItem("appname") +
                      "%26source%3Dqywx%26app%3DStoreManagerDailyReport%26dailyReportShareId%3D" +
                      res.data.data.id +
                      "&response_type=code&scope=snsapi_base&state=#wechat_redirect",
                    imgUrl:
                      "https://wework.qpic.cn/wwpic/32780_rY8ptfwURqioPC8_1684222624/0",
                  },
                },
              },
              function (res) {
                console.log(res, _this.chooseGroup);
                if (res.err_msg == "openExistedChatWithMsg:ok") {
                }
              }
            );
          }
        });
    },
    toggleCheck(val, key) {
      let index = this.form[key].indexOf(val);
      if (index > -1) {
        this.form[key].splice(index, 1);
      } else {
        this.form[key].push(val);
      }
    },
    confirmDrop(e) {
      this.$refs.item.toggle();
      this.getDailyList();
      e.preventDefault();
    },
    onSearch() {
      this.getDailyList();
    },
    cancelDrop(e) {
      this.form.businessChannelIds = [];
      this.form.labelNames = [];
      this.getDailyList();
      this.$refs.item.toggle();
      e.preventDefault();
    },
    toggleDrop(e) {
      this.$refs.item.toggle();
      e.preventDefault();
    },
    openQueryPop() {
      this.queryPop = true;
    },
    closeQueryPop() {
      this.queryPop = false;
    },
    //获取详情
    getStoreDaySalesAnalyseList(id) {
      const that = this;
      let postData = {
        queryMapObject: {},
      };
      if (id) {
        postData.queryMapObject.id = id;
      } else {
        postData.queryMapObject.brand = this.brand.value;
        postData.queryMapObject.dateTime = this.$moment(
          this.currentFormatDate +
            "-" +
            (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
        ).format("yyyy-MM-DD");
      }
      this.axios
        .post("/consumer/store/daysale/getStoreDaySalesAnalyseList", postData)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data) {
            that.analyseList = res.data.data.filter((v) => {
              v.businessChannelName = "";
              that.businessChannelOptions.map((item) => {
                if (item.value == v.businessChannel) {
                  v.businessChannelName = item.label;
                }
              });
              return v.remark;
            });
            that.analysePopup = true;
          }
        });
    },
    goDetail(item) {
      this.$router.push({
        name: "StoreManagerDailyReportDetail",
        params: {
          infos: item,
        },
      });
    },
    // goDetail(item) {
    //   this.$router.push({
    //     name: "StoreManagerDailyReportShareList",
    //     query: {
    //       dailyReportShareId: 1,
    //     },
    //   });
    // },
    getManagerList(e) {
      const that = this;
      this.managerName = e;
      that.sumEnumInfo = {
        storeNum: 0,
        yearTarget: 0,
        yearSum: 0,
        yearCompleteRate: 0,
      }
      var postData = [
        {
          PARAMETER: "Z0CALDAY_MVIM_001",
          SIGN: "I",
          OPTION: "BT",
          LOW: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          )
            .startOf("month")
            .format("yyyy-MM-DD"),
          HIGH: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          ).format("yyyy-MM-DD"),
        },
      ];
      this.managerList.map((v) => {
        that.sumEnumInfo.storeNum++
        that.sumEnumInfo.yearSum += Number(v.yearSumSales)
        postData.push({
          PARAMETER: "Z0CUSTOMER_MVOO_001",
          SIGN: "I",
          OPTION: "EQ",
          LOW_FLAG: "1",
          LOW: v.storeId,
          HIGH: "",
        });
      });
      
      this.axios
        .post("/consumer/bw/BwApi/BWP_ChildSaleList", postData)
        .then((bwRes) => {
          that.showManagerPopover = true;
          console.log("BWP_ChildSaleList", bwRes.data, postData);
          if (bwRes.data && bwRes.data.data && bwRes.data.data.length > 0) {
            bwRes.data.data.map(v=>{
              that.sumEnumInfo.yearTarget += Number(v.YD_AmountIndex)
            })
            that.sumEnumInfo.yearCompleteRate = this.getRate(that.sumEnumInfo.yearSum,that.sumEnumInfo.yearTarget * 10000,1)
          }else{
            that.sumEnumInfo.yearTarget = 0
            that.sumEnumInfo.yearCompleteRate = 0
          }
          that.sumEnumInfo.yearSum = that.getNumber(that.sumEnumInfo.yearSum/10000,1)
        });
        //调用同步接口，保证分享群列表状态正确
        this.axios.get("/consumer/wx/cp/appchat/syncChat?agentId=" + LocalStorage.getItem("agentId")).then((res) => {
          console.log(res);
        });
    },
    goSearch() {
      this.showSearch = true;
    },
    cancelSearch() {
      this.showSearch = false;
      this.keywords = "";
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      }
      if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    chooseDate(e) {
      console.log(e);
      this.currentDate = e;
      this.currentFormatDate = this.$moment(e).format("yyyy-MM");
      this.days = this.$moment(e, "YYYY-MM").daysInMonth();
      this.day = 0;
      this.getDailyList();
      this.showPopover = false;
    },
    getUserInfo() {
      this.loading = true;
      const that = this;
      this.axios
        .get("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/getUserInfo")
        .then((res) => {
          console.log(res, "getUserInfo");
          that.loading = false;
          if (res && res.data && res.data.data) {
            that.roleList = res.data.data.attr.roleList;
            let saleStore = res.data.data.attr.saleStore;
            //格式化下拉列表参数
            saleStore.map((v) => {
              v.text = v.storeId + "_" + v.title;
              v.value = v.storeId;
            });
            that.storeOption = saleStore;
            this.brandOptions = this.brandOptions.filter((v) => {
              return res.data.data.attr.brandRole.brand.indexOf(v.value) > -1;
            });
            this.brand = this.brandOptions[0];
            that.mobile = res.data.data.mobile;
            that.username = res.data.data.username;
            that.getDailyList();
          }
        });
    },

    onClickTab(val) {
      this.day = val;
      this.getDailyList();
    },
    onBrandItemClick(val) {
      this.brand = val;
      this.getDailyList();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    getDailyList() {
      const that = this;
      let postData = {
        queryMapObject: {
          dateTime: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          ).format("yyyy-MM-DD"),
          brand: this.brand.value,
          businessChannelIds: this.form.businessChannelIds,
          labelNames: this.form.labelNames,
          storeKeyword: this.keywords,
        },
      };
      this.axios
        .post("/consumer/store/daysale/getStoreDaySaleList", postData)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data) {
            res.data.data.dataStoreList.map((v) => {
              v.businessChannelName = "";
              this.businessChannelOptions.map((item) => {
                if (item.value == v.businessChannel) {
                  v.businessChannelName = item.label;
                }
              });
              v.branchOfficeName = "";
              this.branchOfficeOptions.map((item) => {
                if (item.id == v.branchOffice) {
                  v.branchOfficeName = item.name;
                }
              });
              v.labelNames = (v.labelNames && v.labelNames.split(",")) || [];
              v.open = false;
              v.goodsOrderRate =
                v.goodsNum == 0 || v.ordersNum == 0
                  ? 0
                  : (v.goodsNum / v.ordersNum).toFixed(2);
            });
            this.dailyList = res.data.data.dataStoreList;
            this.staticSaleMap = res.data.data.staticSaleMap;
            this.storeNum = res.data.data.storeNum;
            this.noDataStoreList = res.data.data.noDataStoreList;
            this.sumInfo = {
              two_years_ago_month_sales: 0,
              before_day_sales: 0,
              before_month_sales: 0,
              month_target: 0,
              year_target: 0,
            };
            if (this.dailyList.length > 0) {
              this.getTargetInfo();
            }
          }
        });
    },
    getTargetInfo() {
      let requestData = [
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "Z0CALDAY_MVIM_001",
          LOW: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          )
            .startOf("month")
            .format("yyyy-MM-DD"),
          HIGH: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          ).format("yyyy-MM-DD"),
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVOO_001",
          LOW: this.brand.value,
          HIGH: "",
          LOW_FLAG: "1",
        },
      ];
      this.dailyList.map((v) => {
        requestData.push({
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZSTORENEW_MVOO_001",
          LOW: v.storeId,
          HIGH: "",
          LOW_FLAG: "1",
        });
      });
      // this.noDataStoreList.map(v=>{
      //   requestData.push({
      //     SIGN: "I",
      //     OPTION: "EQ",
      //     PARAMETER: "ZZSTORENEW_MVOO_001",
      //     LOW: v,
      //     HIGH: "",
      //     LOW_FLAG: "1",
      //   })
      // })
      this.axios
        .post("/consumer/bw/BwApi/storedailyreport_getSalesTarget", requestData)
        .then((res) => {
          let sumInfo = {
            two_years_ago_month_sales: 0,
            before_day_sales: 0,
            before_month_sales: 0,
            month_target: 0,
            year_target: 0,
          };
          console.log("storedailyreport_getSalesTarget", res);
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            temp.map((v) => {
              console.log(v);
              sumInfo.two_years_ago_month_sales += Number(
                v.two_years_ago_month_sales
              );
              sumInfo.before_day_sales += Number(v.before_day_sales);
              sumInfo.before_month_sales += Number(v.before_month_sales);
              sumInfo.month_target += Number(v.month_target);
              sumInfo.year_target += Number(v.year_target);
            });
          }
          this.sumInfo = sumInfo;
        });
    },
    getTags() {
      const that = this;
      let postData = {
        queryMapObject: {},
      };
      this.axios
        .post("/consumer/store/label/getStoreBaseLabelList", postData)
        .then((res) => {
          console.log(res);
          that.labelOptions = res.data.data;
        });
    },
    getNumber(val) {
      let num = Number(val);
      let str = "0";
      if (isNaN(num) || num == "NaN" || num == Infinity || num == -Infinity) {
        return "0";
      } else {
        return (
          Math.floor(num * Math.pow(10, num < 10000 ? 1 : 0)) /
          Math.pow(10, num < 10000 ? 1 : 0)
        );
      }
    },
    getRate(value, total) {
      if (
        isNaN(total) ||
        total == "NaN" ||
        total == Infinity ||
        total == -Infinity
      ) {
        return "-";
      }
      if (total == 0) {
        return "0";
      }
      let val = (parseFloat(value / total) * 100).toFixed(1);
      if (isNaN(val) || val == "NaN" || val == Infinity || val == -Infinity) {
        return "-";
      } else {
        return val + "";
      }
    },
  },
};
